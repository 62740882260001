import React from "react";
import arrowImage from "../../assets/icons/ArrowIcon.svg";
import speech from "../../assets/Textspeec.svg";
import fav from "../../assets/favIcon.svg";
import text from "../../assets/Text - Talentou.svg";
import { useNavigate } from "react-router";
import "./ViewRecordings.css";
import TranscriptBlue from "../../assets/TranscriptBlue.svg";
import ReactPlayer from "react-player";
import { useRef } from "react";
import videoInfo from "../../assets/viewRecinformation.svg";

const playerConfig = {
  file: {
    attributes: {
      controlsList: "nodownload", // Disables the download button
    },
  },
};

const VideoPreview = ({
  type,
  candidateAttentionScore,
  setCandidateScoreGraph,
  transcript,
  videoUrl,
  setVideoPlay,
  videoPlay,
  answer,
  videoEncoded,
  videoUploadedStatus,
  aiCandidateRating,
}) => {
  const navigate = useNavigate();
  const playerRef = useRef(null);

  const handleDisplayGraph = () => {
    setCandidateScoreGraph(true);
    setVideoPlay(false);
  };

  console.log("videoUrl", videoUrl);

  return (
    <div className="video-preview-section">
      <div className="video-preview-heading-icons">
        {type !== "view" && (
          <div className="d-flex align-items-center gap-3">
            <button className="icon-div" onClick={() => navigate(-1)}>
              <img src={arrowImage} alt="" className="backImage" />
            </button>
            <div className="questions-label">
              AI Rating
              <span className="p-1">
                {[...Array(5)].map((star, index) => {
                  index += 1;
                  return (
                    <button
                      type="button"
                      // disabled={editDisable}
                      className="ai-candidate-rating"
                      key={index}
                      disabled={false}
                    >
                      <span
                        className={
                          index <= aiCandidateRating
                            ? "fa fa-star checked ai-candidate-rating"
                            : "fa fa-star ai-candidate-rating"
                        }
                      />
                    </button>
                  );
                })}
              </span>
            </div>
          </div>
        )}

        {type !== "view" && (
          <div
            className={`video-preview-icons ${type !== "view" ? "" : "pt-5"}`}
          >
            <div>
              <button
                onClick={() => handleDisplayGraph()}
                className="candidate-attention-score-container"
                style={{ background: "none", border: "none" }}
              >
                <img
                  src={fav}
                  alt="fav"
                  style={{ height: "28px", width: "30px" }}
                  className=""
                />
                <span className="candidate-attention-score">
                  {candidateAttentionScore}
                </span>
              </button>
            </div>
            <div className="popover_wrapper_view">
              <img src={speech} alt="speech" style={{ zIndex: "1" }} />
              <div className="popover_content_view z-3">
                <div className="popover_heading">
                  <div className="px-3 popover_title">Transcription</div>
                  <img
                    src={TranscriptBlue}
                    alt="red-text"
                    style={{ marginTop: "-13px" }}
                  />
                </div>
                <div className="px-3 mb-2" style={{ fontSize: "14px" }}>
                  {transcript !== ""
                    ? transcript
                    : "No transcription available"}
                </div>
              </div>
            </div>

            <div className="popover_wrapper_view">
              <img src={text} alt="red-text" />
              <div className="popover_content_view z-3">
                <div className="popover_heading">
                  <div className="px-3 popover_title">Text</div>
                  <img
                    src={text}
                    alt="red-text"
                    style={{ marginTop: "-13px" }}
                  />
                </div>
                <div className="px-3 mb-2" style={{ fontSize: "14px" }}>
                  {answer !== "" ? answer : "No text available"}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="view-video-preview">
      {videoUploadedStatus === true ? (
        <ReactPlayer
          url={videoUrl}
          ref={playerRef}
          style={{ position: "absolute" }}
          width="100%"
          config={playerConfig}
          playing={videoPlay}
          controls={true}
          className="video-player-rec"
        />):(
          <div className="video-quality-info py-2">
            <img src={videoInfo} alt="" />
            <div>
            The video upload could not be completed because the browser was either closed or refreshed before the upload process was finalized. 
            Please inform the candidate to reattempt if required and ensure they wait until all videos are fully uploaded. 
            They can monitor the upload progress through the percentage and video count displayed.
            </div>
          </div>
        )}
      </div>

      {videoEncoded === false && videoUploadedStatus === true ? (
        <div className="video-quality-info py-2">
          <img src={videoInfo} alt="" />
          <div>
            This video is under processing, quality may improve once done.
          </div>
        </div>
      ) : (
        <div className="py-2" />
      )}
    </div>
  );
};

export default VideoPreview;
